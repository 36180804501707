import React from "react";

import Header from "../Header/Header";
import Hero from "../UI/Hero";
import Footer from "../Footer/Footer";

export default function Home() {
  return (
    <>
      <Header />
      <main>
        <Hero />
      </main>
      <Footer />
    </>
  );
}
